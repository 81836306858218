import React, { useState, useEffect, useRef, useContext, lazy } from "react";

import { useHistory, useParams } from "react-router-dom";
import { parseISO, format, isSameDay } from "date-fns";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import Badge from "@material-ui/core/Badge";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import MarkdownWrapper from "../MarkdownWrapper";
import { Tooltip } from "@material-ui/core";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import { toast } from "react-toastify";

import { Button, IconButton, Menu, MenuItem, Checkbox } from "@material-ui/core";
import { Visibility  } from "@material-ui/icons";
import { SpyModeContext } from "../../context/SpyMode/SpyModeContext";


	const useStyles = makeStyles((theme) => ({
		ticket: {
			position: "relative",
		},
	
		pendingTicket: {
			cursor: "unset",
		},
	
		noTicketsDiv: {
			display: "flex",
			height: "100px",
			margin: 40,
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "center",
		},
	
		noTicketsText: {
			textAlign: "center",
			color: "rgb(104, 121, 146)",
			fontSize: "14px",
			lineHeight: "1.4",
		},
	
		noTicketsTitle: {
			textAlign: "center",
			fontSize: "16px",
			fontWeight: "600",
			margin: "0px",
		},
	
		contactNameWrapper: {
			display: "flex",
			justifyContent: "space-between",
			alignItems: 'center'
		},
	
		lastMessageTime: {
			justifySelf: "flex-end",
		},
	
		closedBadge: {
			alignSelf: "center",
			justifySelf: "center",
			marginRight: 42,
			paddingRight: 20,
			top: "-2px",
			marginLeft: "auto",
		},
	
		contactLastMessage: {
			paddingRight: 20,
		},
	
		newMessagesCount: {
			alignSelf: "center",
			marginRight: 8,
			marginLeft: "auto",
		},
	
		badgeStyle: {
			color: "white",
			backgroundColor: green[500],
		},
	
		badgeStyleNewMessage: {
			color: "white",
			backgroundColor: "#9b59b6",
		},
	
		acceptButton: {
			position: "absolute",
			left: "50%",
		},
	
		ticketQueueColor: {
			flex: "none",
			width: "8px",
			height: "100%",
			position: "absolute",
			top: "0%",
			left: "0%",
		},
		buttonTag: {
			display: 'flex',
			border: "1px solid #CCC",
			padding: '0px 6px',
			borderRadius: 10,
			fontSize: "0.9em",
			marginRight: '5px'
		},
	}));

  	const TagsLazy = lazy(()=>import(`../TagDropDown`))
	const ProductsLazy = lazy(()=>import(`../ProductDropDown`))
  
const TicketListItem = ({ ticket, currentTab, tagList, productList }) => {
	const classes = useStyles();
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const { ticketId } = useParams();
	const isMounted = useRef(true);
	const { user } = useContext(AuthContext);
	const [selectedTagValue, setSelectedTag] = useState(ticket.tagId || "");
	const [selectedProductValue, setSelectedProduct] = useState(ticket.productId || "");
	const [Tag, setTag] = useState({});
	const [Product, setProduct] = useState({});
	const [anchorElTag, setAnchorElTag] = useState(null);
	const [anchorElProduct, setAnchorElProduct] = useState(null);
	const openTag = Boolean(anchorElTag);
	const openProduct = Boolean(anchorElProduct);

	const { spyMode } = useContext(SpyModeContext);

	const handleClickTag = (event) => {
		setAnchorElTag(event.currentTarget);
	};

	const handleCloseTag = () => {
		setAnchorElTag(null);
	};

	const handleClickProduct = (event) => {
		setAnchorElProduct(event.currentTarget);
	};

	const handleCloseProduct = () => {
		setAnchorElProduct(null);
	};
	

	useEffect(() => {
		if (tagList.length > 0 && ticket.tagId != null) {
		  setTag(tagList.find(tag => tag.id === ticket.tagId));
		}
	}, [ticket, tagList])

	useEffect(() => {
		if (productList.length > 0 && ticket.productId != null) {
			setProduct(productList.find(product => product.id === ticket.productId));
		}
	}, [ticket, productList])
	
	const handleSelectTicket = (id) => {
	  history.push(`/tickets/${id}`);
	};
	
	const calcularCorDoTexto = (cor) => {
		const r = parseInt(cor.slice(1, 3), 16);
		const g = parseInt(cor.slice(3, 5), 16);
		const b = parseInt(cor.slice(5, 7), 16);
		const luminosidade = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
		return luminosidade > 0.68 ? 'black' : 'white';
	};
	
	return (
	  <React.Fragment key={ticket.id}>
		<ListItem
		  dense
		  button
		  onClick={(e) => {
			if (ticket.status === "pending") return;
			handleSelectTicket(ticket.id);
		  }}
		  selected={ticketId && +ticketId === ticket.id}
		  className={clsx(classes.ticket, {
			[classes.pendingTicket]: ticket.status === "pending",
		  })}
		>
		  <Tooltip
			arrow
			placement="right"
			title={ticket.queue?.name || "Sem fila"}
		  >
			<span
			  style={{ backgroundColor: ticket.queue?.color || "#7C7C7C" }}
			  className={classes.ticketQueueColor}
			></span>
		  </Tooltip>
		  <ListItemAvatar>
			<Avatar src={ticket?.contact?.profilePicUrl} />
		  </ListItemAvatar>
		  <ListItemText
			disableTypography
			primary={
			  <span className={classes.contactNameWrapper}>
				<Typography
				  noWrap
				  component="span"
				  variant="body2"
				  color="textPrimary"
				  style={{
					marginRight: "auto"
				  }}
				>
				  {ticket.contact.name}
				</Typography>
				{ticket.status === "closed" && (
				  <Badge
					className={classes.closedBadge}
					badgeContent={"Encerrada"}
					color="primary"
				  />
				)}
				{(ticket.status !== 'pending' && !ticket.isGroup) &&  (
    				<Button
					className={classes.buttonTag}
					aria-haspopup="true"
					style={{
					  backgroundColor: Product?.color,
					  color: calcularCorDoTexto(Product?.color || '#ffffff')
					}}
					onClick={(e) => {
					  e.stopPropagation();
					  setAnchorElProduct(e.currentTarget); 
					}}
				  >
					{Product?.name || "..."}
				  </Button>
				  
				)}

				{(ticket.status !== 'pending' && !ticket.isGroup) &&  (
    				<Button
					className={classes.buttonTag}
					aria-haspopup="true"
					style={{
					  backgroundColor: Tag?.color,
					  color: calcularCorDoTexto(Tag?.color || '#ffffff')
					}}
					onClick={(e) => {
					  e.stopPropagation();
					  setAnchorElTag(e.currentTarget); 
					}}
				  >
					{Tag?.name || "..."}
				  </Button>
				  
					)}

				 {openTag && <React.Suspense fallback={'...Carregando'}><TagsLazy ticket = {ticket} openTag={openTag} anchorElTag={anchorElTag} setAnchorElTag={setAnchorElTag} handleClickTag={handleClickTag} handleCloseTag={handleCloseTag} tagList={tagList}/> </React.Suspense>}

				 {openProduct && <React.Suspense fallback={'...Carregando'}><ProductsLazy ticket = {ticket} openProduct={openProduct} anchorElProduct={anchorElProduct} setAnchorElProduct={setAnchorElProduct} handleClickProduct={handleClickProduct} handleCloseProduct={handleCloseProduct} productList={productList}/> </React.Suspense>}
			
  
				{ticket.lastMessage && (
				  <Typography
					className={classes.lastMessageTime}
					component="span"
					variant="body2"
					color="textSecondary"
				  >
					{isSameDay(
					  parseISO(
						ticket.lastInteractionDate == null
						  ? ticket.updatedAt
						  : ticket.lastInteractionDate
					  ),
					  new Date()
					) ? (
					  <>
						{format(
						  parseISO(
							ticket.lastInteractionDate == null
							  ? ticket.updatedAt
							  : ticket.lastInteractionDate
						  ),
						  "HH:mm"
						)}
					  </>
					) : (
					  <>
						{format(
						  parseISO(
							ticket.lastInteractionDate == null
							  ? ticket.updatedAt
							  : ticket.lastInteractionDate
						  ),
						  "dd/MM/yyyy"
						)}
					  </>
					)}
				  </Typography>
				)}
			  </span>
			}
			secondary={
			  <span className={classes.contactNameWrapper}>
				<Typography
				  className={classes.contactLastMessage}
				  noWrap
				  component="span"
				  variant="body2"
				  color="textSecondary"
				>
				  {ticket.lastMessage ? (
					<MarkdownWrapper>{ticket.lastMessage}</MarkdownWrapper>
				  ) : (
					<br />
				  )}
				</Typography>
				  
				  {console.log(ticket)}

				<Badge
				  className={classes.newMessagesCount}
				  badgeContent={ticket.unreadMessages}
				  classes={{
					badge:
					  ticket.newMessage == 1
						? classes.badgeStyleNewMessage
						: classes.badgeStyle,
				  }}
				/>
			  </span>
			}
		  />
		  {ticket.status === "pending"  && (
			<ButtonWithSpinner
			  color="primary"
			  variant="contained"
			  className={classes.acceptButton}
			  size="small"
			  loading={loading}
			  onClick={(e) => handleSelectTicket(ticket.id)}
			>
			  {i18n.t("ticketsList.buttons.accept")}
			</ButtonWithSpinner>
  
			// ...
		  )}
		  {ticket.status === "pending" &&
			(user.profile.toUpperCase() === "ADMIN" ||
			  user.profile.toUpperCase() === "SUPERADMIN") && (
			  <IconButton
				size="small"
				color="primary"
				variant="contained"
				title="Modo Espião"
				onClick={(e) => handleSelectTicket(ticket.id)}
			  >
				<Visibility />
			  </IconButton>
			)}
		</ListItem>
		<Divider variant="inset" component="li" />
	  </React.Fragment>
	);
  };
  export default TicketListItem;